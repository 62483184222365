import { default as confirmationPQ9HeAmyw8Meta } from "/app/pages/auth/confirmation.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as registerTCvAsPw3TEMeta } from "/app/pages/auth/register.vue?macro=true";
import { default as restorewGbhKRNdzjMeta } from "/app/pages/auth/restore.vue?macro=true";
import { default as buyFBn5zedQwAMeta } from "/app/pages/buy.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as paymentsF6kC2sgIAjMeta } from "/app/pages/payments.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as indexkrtck9uinoMeta } from "/app/pages/spreads/index.vue?macro=true";
import { default as editOEbVlAEvl4Meta } from "/app/pages/users/[id]/edit.vue?macro=true";
import { default as indexnq54o81oozMeta } from "/app/pages/users/index.vue?macro=true";
export default [
  {
    name: "auth-confirmation",
    path: "/auth/confirmation",
    meta: confirmationPQ9HeAmyw8Meta || {},
    component: () => import("/app/pages/auth/confirmation.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerTCvAsPw3TEMeta || {},
    component: () => import("/app/pages/auth/register.vue")
  },
  {
    name: "auth-restore",
    path: "/auth/restore",
    meta: restorewGbhKRNdzjMeta || {},
    component: () => import("/app/pages/auth/restore.vue")
  },
  {
    name: "buy",
    path: "/buy",
    component: () => import("/app/pages/buy.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "payments",
    path: "/payments",
    component: () => import("/app/pages/payments.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/app/pages/profile.vue")
  },
  {
    name: "spreads",
    path: "/spreads",
    meta: indexkrtck9uinoMeta || {},
    component: () => import("/app/pages/spreads/index.vue")
  },
  {
    name: "users-id-edit",
    path: "/users/:id()/edit",
    component: () => import("/app/pages/users/[id]/edit.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/app/pages/users/index.vue")
  }
]