import type {ApiAuthRegisterRequest} from "~/server/api/auth/register.post";
import {set, watchImmediate} from "@vueuse/shared";
import {
    boolean,
    check,
    email,
    InferOutput,
    length,
    minLength,
    nonEmpty,
    object,
    optional,
    pipe,
    required,
    safeParser,
    string
} from "valibot";
import type {FormSubmitEvent} from "#ui/types";
import type {ApiAuthLoginRequest} from "~/server/api/auth/login.post";
import type {ApiAuthConfirmRequest} from "~/server/api/auth/confirm.post";
import {SessionAuthDataMe} from "~/server/utils/auth";
import {VersionAction, VersionEntity} from "~/server/utils/version";
import type {ApiAuthRestoreRequest} from "~/server/api/auth/restore.post";
import type {ApiAuthUpdateRequest} from "~/server/api/auth/me.put";
import type {ApiAuthChangePasswordRequest} from "~/server/api/auth/change_password.put";
import {UserStatus} from "@prisma/client";
import {useIntervalFn} from "@vueuse/core";

export type UseAuthRegisterState = ApiAuthRegisterRequest['body'] & {
    privacy_policy: boolean
}

export const useAuth = () => {

    const me = useState<SessionAuthDataMe | undefined>(() => undefined)

    const watchMe = async () => {
        const handle = async (newValue: User | undefined) => {
            const currentRouteName = useRoute().name
            if (newValue && UserStatus.BANNED === newValue.status) {
                await useAuth().logout()
                await useRouter().push({
                    name: 'auth-login'
                })
            } else if (newValue && UserStatus.EMAIL_CONFIRMATION !== newValue.status && ['auth-login', 'auth-register', 'auth-restore'].includes(currentRouteName)) {
                await useRouter().push({
                    name: 'index'
                })
            } else if (newValue && UserStatus.EMAIL_CONFIRMATION !== newValue.status && currentRouteName === 'auth-confirmation') {
                await useRouter().push({
                    name: 'index'
                })
            } else if (newValue && UserStatus.EMAIL_CONFIRMATION === newValue.status && currentRouteName !== 'auth-confirmation') {
                await useRouter().push({
                    name: 'auth-confirmation'
                })
            } else if (!newValue && !['auth-login', 'auth-register', 'auth-restore'].includes(currentRouteName)) {
                await useRouter().push({
                    name: 'auth-login'
                })
            }
        }

        watchImmediate(me, async (newValue) => {
            await handle(newValue)
        }, {deep: true})

        useIntervalFn(async () => {
            await handle(toValue(me))
        })

        await handle(toValue(me))
    }

    const getMe = async () => {
        const {listenToUpdates} = useVersionListener()
        await listenToUpdates({
            entities: [
                VersionEntity.USER,
                VersionEntity.SUBSCRIPTION,
                VersionEntity.PAYMENT
            ],
            onEvent: async () => {
                set(
                    me,
                    await $fetch('/api/auth/me')
                )
            },
            actions: [
                VersionAction.DELETE,
                VersionAction.UPDATE,
                VersionAction.CREATE
            ]
        })
    }

    const getLoginForm = () => {

        const schema = required(
            object({
                login: pipe(string(), email()),
                password: string()
            })
        )

        type UseAuthLoginSchema = InferOutput<typeof schema>

        const state = reactive<ApiAuthLoginRequest['body']>({
            login: '',
            password: ''
        })

        const pending = shallowRef(false)

        const onSubmit = async (event: FormSubmitEvent<UseAuthLoginSchema>) => {
            if (toValue(pending))
                return
            set(pending, true)
            try {
                await $fetch('/api/auth/login', {
                    body: event.data,
                    method: 'post'
                })
            } catch (e) {
                const {add} = useToast()
                add({
                    title: 'Error',
                    description: 'Invalid credentials',
                    color: 'red'
                })
            } finally {
                set(pending, false)
            }

        }

        return {
            schema: safeParser(schema),
            state,
            onSubmit,
            pending: readonly(pending)
        }
    }

    const getRegisterForm = () => {

        const schema = optional(
            required(
                object({
                    email: pipe(string(), nonEmpty(), email()),
                    password: pipe(nonEmpty(), string(), minLength(8)),
                    phone: string(),
                    privacy_policy: pipe(boolean(), check(input => input ? true : 'Must be checked'))
                }),
                [
                    'email', 'password'
                ]
            ),
            [
                'phone'
            ]
        )

        type UseAuthRegisterSchema = InferOutput<typeof schema>

        const state = reactive<UseAuthRegisterState>({
            email: '',
            password: '',
            phone: ''
        })

        const pending = shallowRef(false)

        const onSubmit = async (event: FormSubmitEvent<UseAuthRegisterSchema>) => {
            if (toValue(pending))
                return
            set(pending, true)
            try {
                await $fetch('/api/auth/register', {
                    body: event.data,
                    method: 'post'
                })
            } catch (e) {
                const {add} = useToast()
                add({
                    title: 'Error',
                    description: 'Email exists',
                    color: 'red'
                })
            } finally {
                set(pending, false)
            }
        }

        return {
            schema: safeParser(schema),
            state,
            onSubmit,
            pending: readonly(pending)
        }
    }

    const getConfirmForm = () => {

        const schema = required(
            object({
                otp: pipe(string(), length(6)),
            })
        )

        type UseAuthConfirmSchema = InferOutput<typeof schema>

        const state = reactive<ApiAuthConfirmRequest['body']>({
            otp: ''
        })

        const pending = shallowRef(false)

        const onSubmit = async (event: FormSubmitEvent<UseAuthConfirmSchema>) => {
            if (toValue(pending))
                return
            set(pending, true)
            try {
                await $fetch('/api/auth/confirm', {
                    body: event.data,
                    method: 'post'
                })
            } catch (e) {
                const {add} = useToast()
                add({
                    title: 'Error',
                    description: 'Code is invalid',
                    color: 'red'
                })
            } finally {
                set(pending, false)
            }
        }

        return {
            schema: safeParser(schema),
            state,
            onSubmit,
            pending: readonly(pending)
        }
    }

    const getRestoreForm = () => {

        const schema = required(
            object({
                email: pipe(string(), email()),
            })
        )

        type UseAuthRestoreSchema = InferOutput<typeof schema>

        const state = reactive<ApiAuthRestoreRequest['body']>({
            email: ''
        })

        const pending = shallowRef(false)

        const onSubmit = async (event: FormSubmitEvent<UseAuthRestoreSchema>) => {
            if (toValue(pending))
                return
            set(pending, true)
            const {add} = useToast()
            try {
                await $fetch('/api/auth/restore', {
                    body: event.data,
                    method: 'post'
                })
                const {push} = useRouter()
                add({
                    description: 'Now you can update your password in settings',
                    color: 'blue'
                })
            } catch (e) {
                add({
                    title: 'Error',
                    description: 'Account not found',
                    color: 'red'
                })
            } finally {
                set(pending, false)
            }
        }

        return {
            schema: safeParser(schema),
            state,
            onSubmit,
            pending: readonly(pending)
        }
    }

    const getUpdateForm = () => {

        const schema = optional(
            object({
                phone: string(),
            }),
            [
                'phone'
            ]
        )

        type UseAuthUpdateSchema = InferOutput<typeof schema>

        const state = reactive<ApiAuthUpdateRequest>({
            phone: toValue(me).phone
        })

        watchImmediate(me, newValue => {
            state.phone = newValue?.phone
        }, {deep: true})

        const onSubmit = async (event: FormSubmitEvent<UseAuthUpdateSchema>) => {
            try {
                await $fetch('/api/auth/me', {
                    body: event.data,
                    method: 'put'
                })
                const {add} = useToast()
                add({
                    title: 'Success',
                    description: 'Profile updated',
                    color: 'green'
                })
            } catch (e) {
                const {add} = useToast()
                add({
                    title: 'Error',
                    description: 'Something went wrong',
                    color: 'red'
                })
            }
        }

        return {
            schema: safeParser(schema),
            state,
            onSubmit
        }
    }

    const getChangePasswordForm = () => {

        const schema = required(
            object({
                password: pipe(string(), minLength(8)),
                old_password: pipe(string(), minLength(8)),
            })
        )

        type UseAuthChangePasswordSchema = InferOutput<typeof schema>

        const state = reactive<ApiAuthChangePasswordRequest>({
            password: '',
            old_password: ''
        })

        const onSubmit = async (event: FormSubmitEvent<UseAuthChangePasswordSchema>) => {
            try {
                await $fetch('/api/auth/change_password', {
                    body: event.data,
                    method: 'put'
                })
                const {add} = useToast()
                add({
                    title: 'Success',
                    description: 'Password changed updated',
                    color: 'green'
                })
                Object.assign(state, {password: '', old_password: ''})
            } catch (e) {
                const {add} = useToast()
                add({
                    title: 'Error',
                    description: 'Something went wrong',
                    color: 'red'
                })
            }
        }

        return {
            schema: safeParser(schema),
            state,
            onSubmit
        }
    }

    const logout = async () => {
        await $fetch('/api/auth/logout', {
            method: 'post'
        })
    }

    return {
        getLoginForm,
        getRegisterForm,
        getConfirmForm,
        getRestoreForm,
        getUpdateForm,
        getChangePasswordForm,
        getMe,
        logout,
        me,
        watchMe
    }
}
