export const enum VersionEntity {
    USER,
    SPREAD,
    SUBSCRIPTION,
    PAYMENT
}

export const enum VersionAction {
    CREATE,
    UPDATE,
    DELETE
}

export type VersionEvent = {
    entity: VersionEntity
    action: VersionAction
    id: string
}

export type VersionUpdateEntityArguments = Omit<VersionEvent, 'id'>

export type VersionSubscribeToUpdatesArguments = {
    callback: (event: VersionEvent) => void | Promise<void>
}

export type VersionSubscribeToUpdatesReturn = {
    unsubscribe: () => void
}

const listeners: Record<string, (event: VersionEvent) => void> = {}

let lastUsedEventId = 1
let lastUsedListenerId = 1

export const updateEntity = ({entity, action}: VersionUpdateEntityArguments) => {
    for (const listenerId in listeners) {
        const listener = listeners[listenerId]
        listener({
            entity,
            action,
            id: lastUsedEventId++
        })
    }
}

export const subscribeToUpdates = ({
                                       callback
                                   }: VersionSubscribeToUpdatesArguments): VersionSubscribeToUpdatesReturn => {

    const listenerId = lastUsedListenerId++

    listeners[listenerId] = callback

    const unsubscribe = () => {
        if (listeners[listenerId])
            delete listeners[listenerId]
    }

    return {
        unsubscribe
    }
}