import {UserStatus} from "@prisma/client";
import {useIntervalFn, watchDeep} from "@vueuse/core";

export default defineNuxtRouteMiddleware(async (to, from) => {
    //FIXME now handled by composable. Use if needed
    return;

    const {me} = useAuth()

    const handle = async () => {
        const meValue = toValue(me)

        if (to.name === 'auth-register') {
            //Doing nothing
        } else if (!meValue?.id && to.name !== 'auth-login' && to.name !== 'auth-restore') {
            await useRouter().push({
                name: 'auth-login'
            })
        } else if (meValue?.status === UserStatus.EMAIL_CONFIRMATION && to.name !== 'auth-confirmation') {
            await useRouter().push({
                name: 'auth-confirmation'
            })
        } else if (meValue?.status === UserStatus.BANNED) {
            //TODO implement banned
        } else if (meValue && (to.name === 'auth-login' || to.name === 'auth-register')) {
            await useRouter().push({
                name: 'index'
            })
        } else if (meValue && meValue.status !== UserStatus.EMAIL_CONFIRMATION && to.name === 'auth-confirmation') {
            await useRouter().push({
                name: 'index'
            })
        }
    }

    watchDeep(
        me,
        handle
    )

    useIntervalFn(() => {
        handle(toValue(me))
    }, 1000)

    await handle()
})
