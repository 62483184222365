import {useEventSource, watchDeep} from "@vueuse/core";
import type {VersionEntity, VersionEvent} from "~/server/utils/version";
import {VersionAction} from "~/server/utils/version";
import {set, watchImmediate} from "@vueuse/shared";

export type UseVersionListenerListenToUpdatedArguments = {
    entities: VersionEntity[]
    onEvent: () => Promise<void> | void,
    actions: VersionAction[]
    immediate?: boolean
}

export const useVersionListener = () => {
    const lastEvent = useState<VersionEvent | undefined>(() => undefined)

    const initialize = () => {
        const {data} = useEventSource('/api/version', undefined, {
            autoReconnect: true
        })
        watchImmediate(data, newValue => {
            if (newValue) {
                set(
                    lastEvent,
                    JSON.parse(newValue)
                )
            }
        })
    }

    const listenToUpdates = async ({
                                       entities,
                                       onEvent,
                                       actions,
                                       immediate
                                   }: UseVersionListenerListenToUpdatedArguments) => {
        if (immediate !== false)
            await onEvent()
        watchDeep(lastEvent, async (newValue) => {
            if (
                newValue &&
                entities.map(e => Number(e)).includes(Number(newValue.entity)) &&
                actions.map(a => Number(a)).includes(Number(newValue.action))
            )
                await onEvent()
        })
    }

    return {
        initialize,
        listenToUpdates
    }
}
