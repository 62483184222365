<template>
  <NuxtLoadingIndicator/>
  <UNotifications/>
  <NuxtLayout>
    <NuxtPage/>
  </NuxtLayout>
</template>

<script lang="ts" setup>
const {initialize} = useVersionListener()
initialize()

const {subscribeToNewSpreads} = useSpread()
subscribeToNewSpreads()
</script>
