import {boolean, InferOutput, minValue, number, object, partial, pipe, required, safeParser, string} from "valibot";
import type {ApiSpreadListRequest} from "~/server/api/spread/index.get";
import type {FormSubmitEvent} from "#ui/types";
import {set} from "@vueuse/shared";
import {VersionAction, VersionEntity} from "~/server/utils/version";
import {useSound} from '@vueuse/sound'
import notificationSound from '~/assets/sound/notification.wav'
import {useLocalStorage, useNow, watchDeep} from "@vueuse/core";
import {UserRole} from "@prisma/client";

export const useSpread = () => {
    const getListForm = () => {

        const schema = required(
            object({
                filter: partial(
                    object({


                        isInClosed: boolean(),
                        isOutClosed: boolean(),
                        simbol: string(),
                        areContractsMatching: boolean(),
                        isMarginLoan: boolean(),
                        buyFromPlatform: string(),
                        averageBuyPrice: partial(
                            object({
                                from: number(),
                                to: number(),
                            })
                        ),
                        volumeBuyUsdt: partial(
                            object({
                                from: number(),
                                to: number(),
                            })
                        ),
                        capacityBuy: partial(
                            object({
                                from: number(),
                                to: number(),
                            })
                        ),
                        ordersCountToBuy: partial(
                            object({
                                from: number(),
                                to: number(),
                            })
                        ),
                        sellOnPlatform: string(),
                        averageSellPrice: partial(
                            object({
                                from: number(),
                                to: number(),
                            })
                        ),
                        volumeSellUsdt: partial(
                            object({
                                from: number(),
                                to: number(),
                            })
                        ),
                        capacitySell: partial(
                            object({
                                from: number(),
                                to: number(),
                            })
                        ),
                        ordersCountToSell: partial(
                            object({
                                from: number(),
                                to: number(),
                            })
                        ),
                        spotCommission: partial(
                            object({
                                from: number(),
                                to: number(),
                            })
                        ),
                        transfer: partial(
                            object({
                                from: number(),
                                to: number(),
                            })
                        ),
                        currencyTransfer: string(),
                        network: string(),
                        netSpread: partial(
                            object({
                                from: number(),
                                to: number(),
                            })
                        ),
                        margin: partial(
                            object({
                                from: number(),
                                to: number(),
                            })
                        ),
                        minTimeConfirmation: partial(
                            object({
                                from: number(),
                                to: number(),
                            })
                        ),
                        maxTimeConfirmation: partial(
                            object({
                                from: number(),
                                to: number(),
                            })
                        ),


                    })
                ),
                order: partial(
                    object({
                        createdAt: 'asc' | 'desc'
                    })
                ),
                pagination: partial(
                    object({
                        size: pipe(number(), minValue(1)),
                        number: pipe(number(), minValue(1))
                    })
                )
            })
        )

        type UseSpreadListSchema = InferOutput<typeof schema>

        const stateInLocalStorage = useLocalStorage<ApiSpreadListRequest['query']>('spread_list_request_state', {
            filter: {
                isInClosed: false,
                isOutClosed: false,
                simbol: undefined,
                areContractsMatching: undefined,
                isMarginLoan: false,
                buyFromPlatform: undefined,
                averageBuyPrice: {
                    from: undefined,
                    to: undefined,
                },
                volumeBuyUsdt: {
                    from: undefined,
                    to: undefined,
                },
                capacityBuy: {
                    from: undefined,
                    to: undefined,
                },
                ordersCountToBuy: {
                    from: undefined,
                    to: undefined,
                },
                sellOnPlatform: undefined,
                averageSellPrice: {
                    from: undefined,
                    to: undefined,
                },
                volumeSellUsdt: {
                    from: undefined,
                    to: undefined,
                },
                capacitySell: {
                    from: undefined,
                    to: undefined,
                },
                ordersCountToSell: {
                    from: undefined,
                    to: undefined,
                },
                spotCommission: {
                    from: undefined,
                    to: undefined,
                },
                transfer: {
                    from: undefined,
                    to: undefined,
                },
                currencyTransfer: undefined,
                network: undefined,
                netSpread: {
                    from: undefined,
                    to: undefined,
                },
                margin: {
                    from: undefined,
                    to: undefined,
                },
                minTimeConfirmation: {
                    from: undefined,
                    to: undefined,
                },
                maxTimeConfirmation: {
                    from: undefined,
                    to: undefined,
                },
            },
            order: {
                createdAt: 'desc'
            },
            pagination: {
                number: 1,
                size: 10
            },
        })

        const state = reactive<ApiSpreadListRequest['query']>(toValue(stateInLocalStorage))

        watchDeep(computed(() => state), newValue => {
            set(stateInLocalStorage, newValue)
        })

        const query = ref(toValue(state))

        const {data, execute, status} = useFetch<User[]>('/api/spread', {
            query: toValue(query),
            immediate: false
        })

        const onSubmit = async (event: FormSubmitEvent<UseSpreadListSchema>) => {
            set(
                query,
                event.data
            )
        }

        const {listenToUpdates} = useVersionListener()
        listenToUpdates({
            entities: [VersionEntity.SPREAD],
            onEvent: async () => {
                await execute()
            },
            actions: [VersionAction.CREATE]
        })

        return {
            schema: safeParser(schema),
            state,
            onSubmit,
            data,
            status
        }
    }

    const subscribeToNewSpreads = () => {

        const {play} = useSound(notificationSound)

        const {me} = useAuth()
        const now = useNow({
            interval: 1000
        })

        const {listenToUpdates} = useVersionListener()
        listenToUpdates({
            entities: [VersionEntity.SPREAD],
            onEvent: async () => {

                const nowValue = toValue(now)
                const nowTimestamp = nowValue.getTime()

                const meValue = toValue(me)

                const hasActiveSubscription = (meValue?.role === UserRole.ADMIN) || (meValue?.UserSubscribtions ?? []).filter(
                    userSubscription =>
                        (new Date(userSubscription.startDate)).getTime() &&
                        (new Date(userSubscription.startDate)).getTime() <= nowTimestamp &&
                        nowTimestamp <= (
                            (new Date(userSubscription.startDate)).getTime() + (
                                userSubscription.Subscribtion.period * 1000 * 60 * 60 * 24
                            )
                        )
                ).length > 0

                if (!hasActiveSubscription)
                    return;

                const {add} = useToast()

                add({
                    title: 'New spread added'
                })
                play()
            },
            actions: [VersionAction.CREATE],
            immediate: false
        })
    }

    const getTotalCountStat = async () => {
        const res = await useFetch('/api/spread/stats/total-count')
        const {listenToUpdates} = useVersionListener()
        await listenToUpdates({
            entities: [
                VersionEntity.SPREAD
            ],
            onEvent: res.execute,
            actions: [
                VersionAction.UPDATE,
                VersionAction.CREATE,
                VersionAction.DELETE
            ]
        })

        return res
    }

    const getTotalProfitStat = async () => {
        const res = await useFetch('/api/spread/stats/total-profit')
        const {listenToUpdates} = useVersionListener()
        await listenToUpdates({
            entities: [
                VersionEntity.SPREAD
            ],
            onEvent: res.execute,
            actions: [
                VersionAction.UPDATE,
                VersionAction.CREATE,
                VersionAction.DELETE
            ]
        })

        return res
    }

    const getTotalCapacityStat = async () => {
        const res = await useFetch('/api/spread/stats/total-capacity')
        const {listenToUpdates} = useVersionListener()
        await listenToUpdates({
            entities: [
                VersionEntity.SPREAD
            ],
            onEvent: res.execute,
            actions: [
                VersionAction.UPDATE,
                VersionAction.CREATE,
                VersionAction.DELETE
            ]
        })

        return res
    }

    return {getListForm, subscribeToNewSpreads, getTotalCountStat, getTotalProfitStat, getTotalCapacityStat}
}
